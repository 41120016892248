.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-radius: 0;
  border-top: 2px solid var(--primary-color);;
  border-left: 1.5px solid var(--primary-color);
  border-right: 1.5px solid var(--primary-color);
  background-color:var(--background-color);
  /* padding: 10px 15px; */
  /* height: 32px; */
}
.nav-tabs .nav-link {
  border-top-left-radius: 0; 
  border-top-right-radius: 0;
}
.nav-tabs{
  /* background-color: var(--primary-color); */
}
.nav-link{
  padding: 0.35rem 0.9rem;
  color: var(--primary-color);
}
.table-hover {
  >tbody>tr:hover>* {
 background-color:var(--background-color);
  }
}
.active>.page-link{
  background-color: var(--primary-color);
}

.page-item{
  /* margin: 10px; */
}
/* .css-1r6h2xi-MuiPaper-root-MuiDrawer-paper {
  border: 5px solid rgba(0, 0, 0, 0.12);
} */

.searchField{
border-radius: 10px;
padding: 1.5px;
border: 1px solid var(--primary-color) ;
}


@keyframes blinkingAnimation {
  0% {
    opacity: 1;
    /* color: 'purple'; */
  }

  50% {
    opacity: 0;
    /* color: red; */
  }

  100% {
    opacity: 1;
    /* color: 'blue'; */
  }
}


@keyframes blinkingAnimationDateTime {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blinkingAnimationFaultPv {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
